import {Flex, Grid, Box} from 'src/components/Box'
import {ActionLink} from 'src/components/Action'
import Card from 'src/components/Card'
import {Header1, Lede, Body} from 'src/components/text'
import {BlueUnderline} from 'src/components/emphasis'
import {ArrowIconToRight} from 'src/components/icons'

interface Props {
  heading: string
  text: string
  linkLabel: string
  linkUrl: string
}
const JobSearchCta = ({heading, text, linkLabel, linkUrl}: Props) => (
  <ActionLink to={linkUrl}>
    <Box maxWidth="660px">
      <Card border="bluePurple">
        <Grid
          gridGap="3"
          alignItems="center"
          gridAutoFlow={{_: 'row', md: 'column'}}
        >
          <Flex flexDirection="column">
            <Lede textAlign={{_: 'center', md: 'left'}}>{heading}</Lede>
            <Body textAlign={{_: 'center', md: 'left'}} color="grey4">
              {text}
            </Body>
          </Flex>
          <Flex alignItems="center" justifyContent="center" mb="2">
            <Header1 textAlign={{_: 'center', md: 'left'}}>
              <BlueUnderline>{linkLabel}</BlueUnderline>
            </Header1>
            <ArrowIconToRight ml="2" />
          </Flex>
        </Grid>
      </Card>
    </Box>
  </ActionLink>
)

export default JobSearchCta

import React, {useState} from 'react'
import {Link} from 'gatsby'

import {Header1, Header3, Lede, Body, Header4} from 'src/components/text'
import Layout from 'src/components/Layout'
import SEO from 'src/components/SEO'
import Button from 'src/components/Button'
import {LocationSearchInput} from 'src/components/form/SearchInput'
import {Flex, Grid, Box} from 'src/components/Box'
import {
  JobLaunchArrowIcon,
  JobLaunchLogoIcon,
  CaretDownIcon,
} from 'src/components/icons'
import {OrderedList, UnorderedList} from 'src/components/List'
import {localPaths} from 'src/urls'
import ButtonLink from 'src/components/ButtonLink'
import Card from 'src/components/Card'
import * as styles from 'src/styles'
import {BlackUnderline} from 'src/components/emphasis'
import {SquiggleDivider} from 'src/components/dividers'
import ColorWall from 'src/components/ColorWall'
import JobSearchCta from 'src/components/JobSearchCta'

const JobSearchLocationForm = () => {
  const [searchInput, setSearchInput] = useState('')
  return (
    <Flex flexDirection="column" alignItems="center">
      <form
        css={{width: '100%', maxWidth: '350px'}}
        action="/job-search/results"
        method="get"
      >
        <LocationSearchInput
          value={searchInput}
          onChange={(e) => setSearchInput(e.currentTarget.value)}
          name="location"
          pattern="[0-9]*"
          placeholder="ZIP Code"
        />
        <Flex justifyContent="center" width="100%" mt="3">
          <Button disabled={!searchInput} type="submit">
            Search Jobs
          </Button>
        </Flex>
      </form>
    </Flex>
  )
}

const PathStep = ({
  children,
  title,
}: {
  children?: React.ReactNode | React.ReactNode[]
  title: string
}) => (
  <Flex flexDirection="column">
    <Lede>{title}</Lede>
    {children}
  </Flex>
)

const JobPath = () => (
  <>
    <Flex flexDirection="column" alignItems="center" mt="10">
      <Lede>Your path to getting a job</Lede>
      <CaretDownIcon mt="2" color="grey3" />
    </Flex>

    <OrderedList mt="6" flowDirection={{_: 'row', lg: 'column'}}>
      <PathStep title="Find a job">
        <Body>
          <Link to={localPaths.chooseAnEmployer}>Choose an employer</Link> that
          is right for you
        </Body>
      </PathStep>

      <PathStep title="Apply">
        <Body>We can help you:</Body>
        <UnorderedList ml="3" flowDirection="row">
          <Link to={localPaths.writeAResume}>write a resume</Link>
          <Link to={localPaths.jobApplicationHelp}>
            complete your job application
          </Link>
        </UnorderedList>
      </PathStep>

      <PathStep title="Succeed">
        <Body>Here's what you can do to:</Body>
        <UnorderedList ml="3" flowDirection="row">
          <Link to={localPaths.prepareForInterview}>
            prepare for your interview
          </Link>
          <Link to={localPaths.buildJobSkills}>be successful on the job</Link>
        </UnorderedList>
      </PathStep>
    </OrderedList>
  </>
)

const ReviewResume = () => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    position="relative"
    mt="8"
    py="4"
  >
    <ColorWall
      css={{background: styles.gradients.mintLakeBlue, opacity: 0.4}}
    />
    <Box maxWidth="335px">
      <Card>
        <Grid gridGap="3" gridAutoFlow="rows">
          <Header3 textAlign="center">
            We’ll review your resume <BlackUnderline>for free</BlackUnderline>.
          </Header3>
          <Body textAlign="center">
            Seriously. Send us your resume, and we’ll get back to you with
            feedback in less than 5 days.
          </Body>

          <ButtonLink to={localPaths.resumeHelp}>Submit your resume</ButtonLink>
        </Grid>
      </Card>
    </Box>
  </Flex>
)

const OneOnOneText = () => (
  <Grid justifyContent="center" gridGap="4" mt="8">
    <Header4 textAlign="center">1-on-1 text help</Header4>
    <Box maxWidth="660px">
      <Lede color="grey4" textAlign="center">
        We provide one-on-one advice and answer your questions over text.
        Anytime.
      </Lede>
    </Box>
    <Flex justifyContent="center">
      <Box>
        <ButtonLink to={localPaths.sendText}>Send us a text</ButtonLink>
      </Box>
    </Flex>
  </Grid>
)

const FindJob = () => (
  <>
    <Header3 textAlign="center">Everything you need</Header3>
    <Lede mt="1" color="grey4" textAlign="center">
      to find and get a job
    </Lede>

    <Flex mt="3" justifyContent="center">
      <JobSearchCta
        heading="There is a lot more"
        text="We’ll walk you through how to find, get, and keep a job."
        linkLabel="Find a Job"
        linkUrl={localPaths.journeys.findAJob}
      />
    </Flex>
  </>
)

const JobSearchPage = () => {
  return (
    <Layout>
      <SEO title="Job Search" permalink={localPaths.jobSearch} />
      <Header1 mt={{_: 8, lg: 10}} textAlign="center">
        <JobLaunchArrowIcon mr="1" />
        <JobLaunchLogoIcon height="26px" />
      </Header1>
      <Header3 as="h2" mt="3" textAlign="center">
        The #1 Job Search Tool for Young People
      </Header3>
      <Lede mt="2" mb="6" textAlign="center">
        Start searching for entry-level jobs near you
      </Lede>
      <JobSearchLocationForm />

      <JobPath />

      <ReviewResume />

      <OneOnOneText />

      <SquiggleDivider mt="8" mb="6" />

      <FindJob />
    </Layout>
  )
}

export default JobSearchPage
